<template>
  <div>
    <!-- choose files modals -->
    <ModalsConfirm :show="addMediaModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
          @click="closeAddMediaModals"
        >
          ✖
        </div>
      </div>
      <img
        slot="actionQuestion"
        src="@/assets/images/media-icon.png"
        alt="media-icon"
        class="m-auto"
      />
      <div slot="actionQuestion">
        <h1 class="mt-4 font-bold text-2xl">Choose a File</h1>
        <p class="mt-4 font-proximaLight text-sm text-subheaderText">
          Medical files are supported, in addition to image and PDF format.
          <br /><br />
          You may take a new photo or upload an existing file from your device
          (max 35MB).
        </p>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <IconButton
          component="PaperclipIcon"
          iconColor="#fff"
          title="Upload File"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-5/12"
          class="mr-2 py-4"
          @click.native="openFiles()"
        />
        <IconButton
          component="CameraIcon"
          iconColor="#fff"
          title="Take Photo"
          background="bg-primary"
          paddingX="px-8"
          width="w-10/12 md:w-5/12"
          class="ml-2"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- choose files modals end -->

    <!-- preview files modals -->
    <ModalsConfirm :show="confirmFileModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-5 -right-10 text-lg font-proximaSemibold cursor-pointer"
          @click="confirmFileModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionQuestion">
        <img :src="imgUrl" :alt="imgUrl" />
        <h1 class="my-4 text-2xl">Attach Photo?</h1>
      </div>
      <div slot="actionText" class="pb-6 flex justify-center items-center">
        <input class="" ref="file" type="file" @change="fileName" hidden />
        <BorderedButton
          class="mr-1"
          marginT="mt-2"
          title="Retake"
          paddingX="px-6"
          width="w-6/12"
          @click.native="openFiles()"
        />
        <SubmitButton
          class="ml-1"
          marginT="mt-2"
          title="Confirm"
          background="bg-success"
          paddingX="px-6"
          width="w-6/12"
          @click.native="submitConfirm"
        />
      </div>
      <div slot="leftBtn" class="hidden"></div>
      <div slot="rightBtn" class="hidden"></div>
    </ModalsConfirm>
    <!-- preview files modals end -->
  </div>
</template>

<script>
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import IconButton from "../buttons/IconButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import VideoIcon from "../icons/VideoIcon.vue";
import MediaIcon from "../icons/MediaIcon.vue";
import PaperclipIcon from "../icons/PaperclipIcon.vue";
import MicIcon from "../icons/MicIcon.vue";

export default {
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    IconButton,
    VideoIcon,
    MediaIcon,
    PaperclipIcon,
    MicIcon,
  },
  props: ["addMediaModals"],
  data() {
    return {
      confirmFileModals: false,
      uploadedFileName: "",
      imgUrl: "",
      imgFile: null,
      whichImage: "",
    };
  },
  methods: {
    uploadImageModals(i) {
      this.toggleUploadImageModals = true;
      this.whichImage = i;
      console.log({ i });
    },
    openFiles() {
      let fileInputElement = this.$refs.file;
      fileInputElement.click();
    },
    fileName(e) {
      this.uploadedFileName = e.target.value;
      let file = e.target.files[0];
      this.imgUrl = URL.createObjectURL(file);
      this.imgFile = file;

      this.toggleUploadImageModals = false;
      this.$emit("closeMediaModals", false);
      this.confirmFileModals = true;
    },

    submitConfirm() {
      this.confirmFileModals = false;
      this.$emit("input", this.imgFile, this.imgUrl);
    },
    
    closeAddMediaModals() {
      this.$emit("closeMediaModals", false);
    },
  },
};
</script>

<style></style>
