<template>
  <div class="pb-12">
    <div class="flex justify-start items-end">
      <div class="md:w-6/12"></div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="this.titlemenu1"
          :to1="'/petoverview/new'"
          :titlemenu2="this.titlemenu2"
          :titlemenu3="this.titlemenu3"
          class="pl-4"
        />
      </div>
    </div>
    <div v-if="data">
      <div class="mt-4 flex justify-center items-center flex-col">
        <div>
          <!--  <img
          v-if="url"
          :src="url"
          class="rounded-full w-24 h-24 object-cover"
        /> -->
          <!-- <img
            v-if="data.profile_picture"
            class="rounded-full w-24 h-24 object-cover"
            :src="data.profile_picture"
          /> -->
          <img class="rounded-full w-24 h-24 object-cover" :src="url" />
          <img
            v-if="data.VerificationStatus"
            src="@/assets/images/PetApproval.png"
            class="rounded-full w-5 h-5 object-cover"
          />
        </div>
        <div class="my-2 flex items-center justify-center cursor-pointer">
          <input
            v-if="editable"
            type="file"
            @change="onFileChange"
            id="upload-photo"
            class="-z-1 opacity-0 absolute cursor-pointer"
          />

          <label
            for="upload-photo"
            class="mt-2 font-proximaMedium text-mediumGrey text-xs text-center cursor-pointer hidden"
            >{{ $t("global_add_profile_picture") }}</label
          >
        </div>
       <!--  <div
          v-if="this.data.current_status != 100"
          class="w-max bg-primary rounded-full py-2 px-4 flex items-center"
        >
          <component :is="whichIcon" width="24" height="24" iconColor="#fff" />
          <span class="pl-1 font-proximaLight text-white">{{
            data.current_status_name
          }}</span>
        </div> -->
      </div>
      <PetForms :data="this.data" @editable="putEditable" :file="file" />
    </div>
    <div v-else class="mt-10 text-center font-proximaMedium">
      No Data. <br />
      Go back to Pet Records and select the pet.
    </div>
    <Modals
      :show="showDeleteModalsComp"
      @close="closeModals"
      :title="$t('account_dropdown_menu_delete_pet_records')"
    >
      <div slot="deleteQuestion" class="text-base font-proximaMedium">
        Delete {{ nameOfSpecies.toUpperCase() }} RECORDS?
      </div>
      <div slot="deleteText" class="mt-2 text-xs font-proximaLight">
        By clicking on "Delete" you will permanently erase this
        {{ nameOfSpecies }} records. This cannot be undone.
      </div>
      <div class="w-full" slot="deleteBtn">
        <SubmitButton
          class="md:ml-1"
          :title="$t('global_delete')"
          background="bg-pastelRed"
          paddingX="px-10"
          @click.native="deleteAccount"
        />
      </div>
    </Modals>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import InputImage from "../forms/InputImage.vue";
import PetForms from "./PetForms.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import Modals from "../modals/Modals.vue";

import Status100Icon from "../icons/Status103Icon.vue";
import Status101Icon from "../icons/Status101Icon.vue";
import Status102Icon from "../icons/Status102Icon.vue";
import Status103Icon from "../icons/Status103Icon.vue";
import Status104Icon from "../icons/Status104Icon.vue";
import Status105Icon from "../icons/Status105Icon.vue";
import Status106Icon from "../icons/Status106Icon.vue";
import Status107Icon from "../icons/Status107Icon.vue";
import Status108Icon from "../icons/Status108Icon.vue";
import Status109Icon from "../icons/Status109Icon.vue";
import Status110Icon from "../icons/Status110Icon.vue";
import { nameOfPet, pictureBySpecies } from "../../methods/petMethods";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeaderGlobal,
    InputImage,
    PetForms,
    SubmitButton,
    BorderedButton,
    Modals,
    Status100Icon,
    Status101Icon,
    Status102Icon,
    Status103Icon,
    Status104Icon,
    Status105Icon,
    Status106Icon,
    Status107Icon,
    Status108Icon,
    Status109Icon,
    Status110Icon,
  },
  data() {
    return {
      data: {},
      url: null,
      file: null,
      editable: false,
      titlemenu1: this.$t("account_dropdown_menu_pet_records"),
      titlemenu2: "",
      titlemenu3: "",
      nameOfSpecies: "",
    };
  },
  computed: {
    whichIcon() {
      return "Status" + this.data.current_status + "Icon";
    },
    showDeleteModalsComp() {
      return this.$store.state.showDeleteModalsState;
    },
  },
  created() {
    // Retrieve the pet object from storage
    var retrievedObject = localStorage.getItem("pet");

    this.data = JSON.parse(retrievedObject);

    this.titlemenu2 =
      nameOfPet(this.data.species) + " Record: " + this.data.callName;

    this.nameOfSpecies =
      nameOfPet(this.data.species) == "Other"
        ? "Pet"
        : nameOfPet(this.data.species);
    this.url = this.data.profile_picture
      ? this.data.profile_picture
      : pictureBySpecies(this.data);
  },
  methods: {
    ...mapActions({
      deletePetRecord: "petRecord/deletePetRecord",
    }),
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = file;
      this.url = URL.createObjectURL(file);
      this.$store.commit("pictureState", file);
      this.$emit("blurred", this.url);
    },
    async deleteAccount() {
      try {
        this.deletePetRecord(this.data);
      } catch (error) {
        console.log(error);
      }
    },
    closeModals() {
      this.$store.commit("showDeleteModals", false);
    },
    putEditable() {
      this.editable = !this.editable;
    },
  },
};
</script>

<style></style>
