<template>
  <div class="flex justify-center items-center flex-col">
    <div
      v-if="errors.length > 0"
      class="my-8 flex flex-col justify-center items-center text-red"
    >
      <h5 v-if="errors.length > 0">{{ $t("global_error") }}</h5>
      <ul
        v-for="(error, i) in this.errors"
        :key="i"
        class="mt-2 font-proximaLight text-sm text-left list-disc"
      >
        <li>{{ error }}</li>
      </ul>
    </div>
    <FormError :errors="errorForm" />
    <div
      v-if="speciesComp == 5"
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-start items-start"
    >
      <Input
        label="Species"
        v-model="petData.customSpecies"
        classAdd="w-6/12 mr-2 flex flex-col justify-start items-start"
        :editable="editable"
      />
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-start items-start"
    >
      <Input
        :label="$t('global_name')"
        :editable="editable"
        v-model="petData.callName"
        classAdd="w-6/12 mr-2 flex flex-col justify-start items-start"
        :error="errors.callName"
      />
    </div>
    <!-- pet gender & breeding status -->
    <div class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row items-center">
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          :title="$t('global_sex')"
          :opt1="$t('global_female')"
          :opt2="$t('global_male')"
          v-if="!editable"
          :val="petData.sex == 0 ? 2 : 1"
        />
        <InputRadio
          class="mt-4"
          :title="$t('global_sex')"
          :opt1="$t('global_female')"
          :opt2="$t('global_male')"
          @input="inputSexType"
          v-else
          :val="petData.sex == 0 ? 2 : 1"
        />
      </div>
      <div
        class="w-full flex items-center justify-center"
        v-if="speciesComp != 4"
      >
        <InputRadioDisabledVue
          class="mt-4"
          :title="$t('pet_record_breeding_status')"
          :opt1="$t('global_sterilized')"
          :opt2="$t('global_intact')"
          v-if="!editable"
          :val="petData.breedingStatus == 0 ? 2 : 1"
        />
        <InputRadio
          class="mt-4"
          :title="$t('pet_record_breeding_status')"
          :opt1="$t('global_sterilized')"
          :opt2="$t('global_intact')"
          v-else
          :val="petData.breedingStatus == 0 ? 2 : 1"
          @input="inputBreedingStatus"
        />
      </div>
      <div class="w-full flex items-center justify-center" v-else></div>
    </div>
    <!-- pet gender & breeding status -->
    <div
      v-if="speciesComp == 4"
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row items-center"
    >
      <div class="w-full mr-4 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="origin">Type</label>

        <select
          v-if="!editable"
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="petData.genus"
          disabled
        >
          <option value="" disabled selected>
            {{ $t("global_select_Select_bird_genus") }}
          </option>
          <option v-for="item in genusBirdList" :value="item.id" :key="item.id">
            {{ item.genusName }}
          </option>
        </select>
        <select
          v-else
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="petData.genus"
        >
          <option value="" disabled selected>
            {{ $t("global_select_Select_bird_genus") }}
          </option>
          <option v-for="item in genusBirdList" :value="item.id" :key="item.id">
            {{ item.genusName }}
          </option>
        </select>
        <FormError :errors="errors.CountryofOrigin" />
      </div>
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          :title="$t('pet_record_breeding_status')"
          :opt1="$t('global_sterilized')"
          :opt2="$t('global_intact')"
          v-if="!editable"
        />
        <InputRadio
          class="mt-4"
          :title="$t('pet_record_cites_certificate')"
          :opt1="$t('global_no')"
          :opt2="$t('global_yes')"
          v-else
        />
      </div>
    </div>
    <!-- DOB -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          title="Do you know your pet’s date of birth?*"
          :val="valQuestionDOB"
          opt1="Yes"
          opt2="No"
          v-if="!editable"
        />
        <InputRadio
          class="mt-4"
          title="Do you know your pet’s date of birth?* "
          :val="valQuestionDOB"
          opt1="Yes"
          opt2="No"
          @input="questionDOB = !questionDOB"
          v-else
        />
      </div>

      <div class="w-full mr-2 flex flex-col justify-start items-start">
        <label class="text-sm font-proximaMedium" for="dateOfBirth">{{
          $t("global_date_of_birth") + "**"
        }}</label>
        <date-picker
          v-model="time1"
          valueType="format"
          @update="onChange()"
          :disabled="!editable"
          class="w-full rounded-sm my-1 text-sm"
          :class="{ 'border border-red outline-red': error }"
        />
      </div>
      <!-- <InputForm
        v-else
        :label="$t('global_date_of_birth') + '*'"
        placeholder="Enter approximate age in months"
        v-model="petData.approximateAgeInMonths"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
        :inputClass="classMethod(errors.approximateAgeInMonths, true)"
      /> -->
    </div>
    <!-- DOB end -->
    <!-- ID band number -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          title="Does your pet have permanent ID?*"
          :val="valQuestionIdBand"
          :opt1="$t('global_yes')"
          :opt2="$t('global_no')"
          v-if="!editable"
        />
        <InputRadio
          class="mt-4"
          title="Does your pet have permanent ID?*"
          :opt1="$t('global_yes')"
          :opt2="$t('global_no')"
          @input="questionIdBand = !questionIdBand"
          v-else
        />
      </div>

      <Input
        :label="$t('pet_record_microchip_number')"
        :editable="editable && !questionIdBand"
        v-model="petData.microchipNo"
        classAdd="w-full mr-2 flex flex-col justify-start items-start relative cursor-pointer"
        :error="errors.microchipNo"
      />
    </div>
    <!-- ID band number end -->
    <!-- country of origin -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          title="Is your pet imported?*"
          :val="valQuestionCountryOrigin"
          :opt1="$t('global_yes')"
          :opt2="$t('global_no')"
          v-if="!editable"
        />
        <InputRadio
          class="mt-4"
          title="Is your pet imported?*"
          :val="questionCountryOrigin ? 1 : 2"
          opt1="Yes"
          opt2="No"
          @input="questionCountryOrigin = !questionCountryOrigin"
          v-else
        />
      </div>

      <div
        v-if="questionCountryOrigin"
        class="w-full mr-2 flex flex-col justify-start items-start"
      >
        <label class="text-sm font-proximaMedium" for="origin">{{
          $t("global_country_of_origin") + "**"
        }}</label>
        <select
          v-if="!editable"
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="petData.CountryofOrigin"
          :disabled="questionCountryOrigin"
        >
          <option value="" disabled selected>
            {{ $t("global_select_country") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
        <select
          v-else
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          :class="{
            'bg-greyishWhite': !questionCountryOrigin,
          }"
          v-model="petData.CountryofOrigin"
        >
          <option value="" disabled selected>
            {{ $t("global_select_country") }}
          </option>
          <option v-for="item in countriesList" :value="item.id" :key="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <Input
        v-else
        :label="$t('global_country_of_origin')"
        :editable="false"
        v-model="account.country.name"
        classAdd="w-full mr-2 flex flex-col justify-start items-start"
      />
    </div>
    <!-- country of origin end -->
    <!-- breed -->
    
    <div
      v-if="speciesComp == 1 || speciesComp == 2"
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-center"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          :val="valQuestionPureBred"
          title="Is your pet purebred?*"
          :opt1="$t('global_yes')"
          :opt2="$t('global_no')"
          v-if="!editable"
        />
        <InputRadio
          class="mt-4"
          :val="questionPureBred ? 1 : 2"
          title="Is your pet purebred?*"
          opt1="Yes"
          opt2="No"
          @input="questionPureBred = !questionPureBred"
          v-else
        />
      </div>
      <div
        class="w-full mt-4 md:mt-0 mr-2 flex flex-col justify-start items-start"
      >
        <label class="text-sm font-proximaMedium">{{
          $t("pet_record_breed") + "**"
        }}</label>
        <select
          v-if="!editable"
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          v-model="petData.breed"
          disabled
        >
          <option value="" disabled selected>
            {{ $t("global_select_Select_pet_breed") }}
          </option>
          <option v-for="item in breedsList" :value="item.id" :key="item.id">
            {{ item.breedName }}
          </option>
        </select>
        <select
          v-else
          class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
          :class="{
            'bg-greyishWhite': questionPureBred,
          }"
          :disabled="questionPureBred"
          v-model="petData.breed"
        >
          <option value="" disabled selected>
            Select official registry body
          </option>
          <option v-for="item in breedsList" :value="item.id" :key="item.id">
            {{ item.breedName }}
          </option>
        </select>
        <FormError :errors="errors.breed" />
      </div>
    </div>
    <!-- breed end -->
    <!-- pedegree papers -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between items-start"
    >
      <div class="w-full flex items-center justify-center">
        <InputRadioDisabledVue
          class="mt-4"
          title="Does your pet have pedigree papers?***"
          :val="valQuestionPedPapers"
          :opt1="$t('global_yes')"
          :opt2="$t('global_no')"
          v-if="!editable"
        />
        <InputRadio
          class="mt-4"
          title="Does your pet have pedigree papers?***"
          :opt1="$t('global_yes')"
          :opt2="$t('global_no')"
          @input="questionPedPapers = !questionPedPapers"
          v-else
        />
      </div>
      <div
        v-if="speciesComp == 1 || speciesComp == 2"
        class="w-full mt-4 mr-2 flex flex-col justify-start items-start"
      >
        <Input
          :label="$t('pet_record_official_registered_name')"
          :editable="editable && !questionPedPapers"
          v-model="petData.officialRegisteredName"
          classAdd="w-full ml-0 flex flex-col justify-start items-start relative"
          :error="errors.officialRegisteredName"
        />
        <Input
          :label="$t('pet_record_pedigree_registration_number')"
          :editable="editable && !questionPedPapers"
          v-model="petData.pedigreeNo"
          classAdd="w-full my-2 ml-0 flex flex-col justify-start items-start relative"
          :error="errors.pedigreeNo"
        />
        <div
          class="w-full mt-4 md:mt-0 ml-0 flex flex-col justify-start items-start"
        >
          <label class="text-sm font-proximaMedium">{{
            $t("pet_record_national_registry")
          }}</label>
          <select
            class="w-full bg-white text-mediumGrey rounded-sm px-2 py-3 my-1 text-sm"
            v-model="petData.nationalRegistry"
            :disabled="!editable || questionPedPapers"
          >
            <option value="" disabled selected>
              Select official registry body
            </option>
            <option
              v-for="item in nationalKennelClubsList"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </option>
          </select>
          <FormError :errors="errors.nationalRegistry" />
        </div>
      </div>
    </div>
    <!-- pedegree papers end -->
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <Input
        :label="$t('pet_record_height')"
        :editable="editable"
        v-model="petData.height"
        classAdd="w-full flex flex-col justify-start items-start relative"
        :error="errors.height"
      />
      <Input
        :label="$t('pet_record_weight')"
        :editable="editable"
        v-model="petData.weight"
        classAdd="w-full ml-4 mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start relative"
        :error="errors.weight"
      />
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <Input
        label="Working Titles (optional)"
        v-model="petData.showTitle"
        :editable="editable"
        @update="changeShowTitle"
        placeholder="Multi BISS, AKC Champion, Canadian GrCH"
        classAdd="w-full flex flex-col justify-start items-start relative"
        :error="errors.showTitle"
      />
      <Input
        :label="$t('pet_record_show_titles')"
        v-model="petData.showTitle"
        :editable="editable"
        @update="changeShowTitle"
        placeholder="Multi BISS, AKC Champion, Canadian GrCH"
        classAdd="w-full ml-2 flex flex-col justify-start items-start relative"
        :error="errors.showTitle"
      />
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <Input
        label="Primary ID Type (optional)"
        :editable="editable"
        v-model="petData.optionalIDType"
        classAdd="w-full flex flex-col justify-start items-start relative"
        :error="errors.optionalIDType"
      />
      <Input
        label="Primary ID (optional)"
        :editable="editable"
        v-model="petData.optionalID"
        classAdd=" w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start relative"
        :error="errors.optionalID"
      />
    </div>
    <div
      class="mt-4 w-11/12 md:w-8/12 flex flex-col md:flex-row justify-between"
    >
      <Input
        :label="$t('pet_record_secondary_id_type')"
        :editable="editable"
        v-model="petData.optionalIDType"
        classAdd="w-full flex flex-col justify-start items-start relative"
        :error="errors.optionalIDType"
      />
      <Input
        :label="$t('pet_record_secondary_id')"
        :editable="editable"
        v-model="petData.optionalID"
        classAdd=" w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start relative"
        :error="errors.optionalID"
      />
    </div>
    <div
      class="my-8 w-12/12 md:w-6/12 lg:w-8/12 flex flex-wrap justify-start md:justify-arround items-start"
      v-if="!editable"
    >
      <div v-for="g in petData.petGallery" :key="g.id">
        <displayImagePets
          class="mx-1 mt-4 rounded-md"
          :imageData="g.petImage"
        />
        <div class="filler mx-3"></div>
      </div>
      <!--  <div v-for="g in gall.slice(this.petData.petGallery.length)" :key="g">
        <displayImagePets class="mx-1 mt-4 rounded-md" :imageData="null" />
        <div class="filler mx-3"></div>
      </div> -->
    </div>
    <div
      class="my-8 w-12/12 md:w-6/12 lg:w-8/12 flex flex-wrap justify-star md:justify-arround items-start"
      v-else
    >
      <div
        class="relative"
        v-for="(g, index) in petData.petGallery"
        :key="g.id"
      >
        <TrashIconVue
          bgColor="#F5F5F5"
          iconColor="#0AC0CB"
          :height="24"
          :width="24"
          class="cursor-pointer absolute top-5 right-6"
          @click.native="removeItem(index, g)"
        />
        <displayImagePets
          class="mx-1 mt-4 rounded-md"
          :imageData="g.petImage"
        />
        <div class="filler mx-3"></div>
      </div>
      <div v-for="g in gall.slice(petData.petGallery.length)" :key="g">
        <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />

        <div class="filler mx-3"></div>
      </div>
    </div>
    <!-- unverifiedHealthStatus -->
    <!--   <NewpetDiagnoses
      :unverifiedHealthStatus="petData.unverifiedHealthStatus"
      :editable="editable"
      @changeDignostic="changeDignostic"
      @deleteDiag="deleteDiag"
    /> -->
    <!--  Veterinarian’s Email Address -->

    <!--  <Input :label="$t('pet_record_vet_email_address')" :editable="editable" v-model="this.petData.petVerificationRequest.vetEmailAddress" type="email" classAdd="mt-8 w-11/12 md:w-6/12 lg:w-4/12 text-left font-proximaMedium text-sm" /> -->
    <p class="font-proximaLight">
      <span class="text-red">{{ $t("global_note") }}</span>
      {{ $t("pet_record_text") }}
    </p>

    <!--////////////////////////////////////////////////////////////// BEGIN doc verification ////////////////////////////////////////////////-->
    <div
      v-if="petData.doc"
      class="w-full md:w-4/12 mt-4 flex flex-col md:flex-row items-center md:items-start justify-between"
    >
      <displayImagePets
        v-if="!editable"
        class="mx-1 mt-4 rounded-md"
        :imageData="petData.petIdentificationImage"
      />
      <div class="relative" v-else>
        <img
          class="absolute bottom-0 -right-6 cursor-pointer"
          src="@/assets/images/close-icon.png"
          alt="close-icon"
          @click="removeDocument"
        />
        <displayImagePets
          class="mx-1 mt-4 rounded-md"
          :imageData="documentImage"
          @click.native="!documentImage ? (addMediaModals = true) : null"
        />
      </div>
      <!-- <InputImagePets
        v-else
        class="mx-1 mt-4"
        :imageData="petData.petIdentificationImage"
        @input="pushToDoc"
      /> -->
      <InputRadio3v
        class="mt-4"
        title="Document Type"
        :opt1="$t('pet_record_official_pedegree')"
        :opt2="$t('pet_record_EU_pet_passport')"
        :opt3="$t('pet_record_vet_hospital_bill')"
        :val="petData.petIdentificationImageType"
        @input="inputVal"
        :editable="!editable"
      />
    </div>
    <div
      v-else
      class="w-full md:w-4/12 mt-4 flex flex-col md:flex-row items-center md:items-start justify-between"
    >
      <InputImagePets class="mx-1 mt-4" @input="!editable ? pushToDoc : null" />
      <InputRadio3v
        class="mt-4"
        title="Document Type"
        :opt1="$t('pet_record_official_pedegree')"
        :opt2="$t('pet_record_EU_pet_passport')"
        :opt3="$t('pet_record_vet_hospital_bill')"
        :val="petData.petIdentificationImageType"
        @input="inputVal"
        :editable="!editable"
      />
    </div>
    <DocUploadModalsVue
      :addMediaModals="addMediaModals"
      @closeMediaModals="closeMediaModals"
      @input="pushToDoc1"
    />
    <!--////////////////////////////////////////////////////////////// END doc verification ////////////////////////////////////////////////-->
    <div
      class="mt-0 w-full font-proximaMedium flex justify-center items-center"
    >
      <div
        class="w-11/12 md:w-6/12 lg:w-4/12 md:mt-4 flex flex-row justify-center items-center"
        v-if="!editable"
      >
        <SubmitButton
          class="mr-1"
          paddingX="px-8"
          width="w-full md:w-10/12"
          :title="$t('account_edit_button')"
          background="bg-primary"
          @click.native="editAccount"
        />
        <BorderedButton
          class="ml-1"
          paddingX="px-8"
          width="w-full md:w-10/12"
          :title="$t('global_delete')"
          borderColor="border-red"
          textCl="text-red"
          @click.native="openModal"
        />
      </div>
      <div
        class="w-11/12 md:w-6/12 lg:w-4/12 md:mt-4 flex flex-row justify-center items-center"
        v-else
      >
        <BorderedButton
          class="mr-1"
          paddingX="px-8"
          width="w-full md:w-10/12"
          :title="$t('global_cancel')"
          borderColor="border-mediumGrey"
          textCl="text-darkGrey"
          @click.native="editAccount"
        />
        <SubmitButton
          class="ml-1"
          paddingX="px-8"
          width="w-full md:w-10/12"
          :title="$t('account_save_button')"
          background="bg-primary"
          @click.native="editAccount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InputRadio from "../forms/InputRadio.vue";
import InputRadio3v from "../forms/InputRadio3v.vue";
import FormError from "../warning/FormError.vue";
import NewpetDiagnoses from "./NewpetDiagnoses.vue";
import InputImagePets from "../forms/InputImagePets.vue";
import InputRadioEach from "../forms/InputRadioEach.vue";
import displayImagePets from "../forms/displayImagePets.vue";
import DatePicker from "vue2-datepicker";
import Input from "../forms/Input_.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import "vue2-datepicker/index.css";
import moment from "moment";

import { getCountries } from "../../services/countriesServices";
import {
  breedsBySpecies,
  nationalKennelClubsBySpecies,
  genusBird,
  deletePetGallery,
  deletePetDoc,
  savePetGallery,
} from "../../services/petRecordService";
import { mapActions, mapGetters } from "vuex";
import InputRadioDisabledVue from "../forms/InputRadioDisabled.vue";
import DocUploadModalsVue from "./DocUploadModals.vue";
import TrashIconVue from "../icons/TrashIcon.vue";

export default {
  props: { file: {} },
  components: {
    Input,
    SubmitButton,
    BorderedButton,
    InputRadio,
    InputRadioEach,
    NewpetDiagnoses,
    InputImagePets,
    displayImagePets,
    FormError,
    DatePicker,
    InputRadio3v,
    InputRadioDisabledVue,
    DocUploadModalsVue,
    TrashIconVue,
  },
  data() {
    return {
      petData: {},
      time1: null,
      time2: null,
      editable: false,
      questionDOB: false,
      valQuestionDOB: false,
      valQuestionIdBand: false,
      questionIdBand: false,
      questionCountryOrigin: false,
      valQuestionCountryOrigin: false,
      questionPureBred: false,
      valQuestionPureBred: false,
      questionPedPapers: false,
      valQuestionPedPapers: false,
      addMediaModals: false,
      countriesList: [],
      unverifiedHealthStatus: [],
      nationalKennelClubsList: [],
      breedsList: [],
      petGallery: [],
      picture: null,
      error: false,
      errors: {},
      gall: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      genusBirdList: [],
      documentImage: "",
    };
  },
  computed: {
    ...mapGetters({
      getError: "petRecord/getError",
      account: "auth/user",
    }),
    errorForm() {
      return this.$store.state.errorForm;
    },
    speciesComp() {
      var retrievedObject = localStorage.getItem("pet");
      var petData = JSON.parse(retrievedObject);
      return petData.species;
    },
  },
  async created() {
    // Retrieve the pet object from storage
    var retrievedObject = localStorage.getItem("pet");
    this.petData = JSON.parse(retrievedObject);
    this.petData.petIdentificationImage = this.petData.doc
      ? this.petData.doc.document
      : null;
    this.petData.petIdentificationImageType = this.petData.doc
      ? this.petData.doc.type
      : 1;

    this.documentImage = this.petData.petIdentificationImage;

    const req = {
      email: localStorage.getItem("email"),
      role: localStorage.getItem("role"),
    };

    this.getUser(req);

    console.log(this.petData.workingTitle);
    console.log(this.petData.showTitle);
    const species = this.petData.species;

    const currentDate = new Date();
    const previousDate = new Date(this.petData.dateOfBirth);
    this.petData.height = this.petData.height ? this.petData.height : "0";
    this.petData.weight = this.petData.weight ? this.petData.weight : "0";

    //--------------radio
    console.log(this.petData.dateOfBirth);
    this.valQuestionDOB = this.petData.dateOfBirth == null ? 2 : 1;
    this.questionDOB = this.petData.dateOfBirth == null ? false : true;
    this.valQuestionIdBand = this.petData.microchipNo == null ? 2 : 1;
    this.questionIdBand = this.petData.microchipNo == null ? false : true;
    this.valQuestionCountryOrigin =
      this.petData.CountryofOrigin == null ? 2 : 1;
    this.valQuestionPedPapers =
      this.petData.officialRegisteredName == "" ? 2 : 1;
    this.valQuestionPureBred = this.petData.breed == null ? 2 : 1;
    this.questionCountryOrigin =
      this.petData.CountryofOrigin == null ? false : true;

    const timeDifference = currentDate.getTime() - previousDate.getTime();

    console.log("nnn", this.petData.dateOfBirth);
    this.time1 =
      this.petData.dateOfBirth ||
      this.petData.approximateAgeInMonths + " months";

    this.time2 = new Date(this.petData.dateOfBirth);

    const a = await getCountries();
    this.countriesList = a.data;
    if (species == 1 || species == 2) {
      const b = await breedsBySpecies(species);
      function compareBreeds(a, b) {
        if (a.breedName < b.breedName) return -1;
        if (a.breedName > b.breedName) return 1;
        return 0;
      }
      this.breedsList = b.data.sort(compareBreeds);

      const c = await nationalKennelClubsBySpecies(species);
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      this.nationalKennelClubsList = c.data.sort(compare);
    }
    if (species == 4) {
      const b = await genusBird();
      this.genusBirdList = b.data;
    }
  },
  methods: {
    ...mapActions({
      updatePetRecord: "petRecord/updatePetRecord",
      getUser: "auth/getUser",
    }),
    async removeDocument() {
      this.documentImage = "";
      this.petData.petIdentificationImage = "";
      this.petData.doc ? await deletePetDoc(this.petData.doc.id) : null;
    },

    async removeItem(index, guallery) {
      await deletePetGallery(guallery.id);
      this.petData.petGallery.splice(index, 1);
    },
    changeWorkingTitle() {
      val.split(",").map((line) => {
        console.log(line);
        if (
          val[val.length - 1] == "," &&
          this.petData.workingTitle.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.petData.workingTitle.push(line);
        }
      });
    },
    changeShowTitle() {
      val.split(",").map((line) => {
        console.log(line);
        if (
          val[val.length - 1] == "," &&
          this.petData.showTitle.findIndex((obj) => obj === line) == -1 &&
          line != ""
        ) {
          this.petData.showTitle.push(line);
        }
      });
    },

    changeDignostic(val) {
      //findIndex((obj) => obj.name === line)
      val.split(",").map((line) => {
        if (
          val[val.length - 1] == "," &&
          this.unverifiedHealthStatus.findIndex((obj) => obj === line.text) ==
            -1 &&
          line != ""
        ) {
          this.unverifiedHealthStatus.push(line.text);
        }
      });
      console.log("unverifiedHealthStatus --- ", this.unverifiedHealthStatus);
    },
    inputVal(i) {
      console.log("***", i);
      this.petData.petIdentificationImageType = i;
      console.log("***", this.petData.petIdentificationImageType);
    },
    inputSexType(val) {
      //0 for male / 1 for Female
      this.petData.sex = val == 1 ? true : false;
    },
    inputBreedingStatus(val) {
      this.petData.breedingStatus = val == 1 ? true : false;
      console.log("------------------0 intact", this.petData.breedingStatus);
    },
    inputPedigree(val) {
      this.petData.pedigree = val == 2 ? true : false;
      console.log("--- pedigree", this.petData.pedigree);
    },
    inputpurebredStatus(val) {
      this.petData.purebredStatus = val == 2 ? true : false;
    },

    deleteDiag(val) {
      const diags = this.unverifiedHealthStatus.filter((c) => c.id !== val);
      this.unverifiedHealthStatus = diags;
    },
    async pushToGallery(val) {
      //await savePetGallery(userEmailAddress, petImage, petID, 0);
      console.log(val);
      this.petGallery.push(val);
      //this.petData.petGallery.push(val);
    },
    pushToDoc(val) {
      this.petData.petIdentificationImage = val;
    },
    pushToDoc1(imgFile, imgUrl) {
      this.petData.petIdentificationImage = imgFile;
      this.documentImage = imgUrl;
    },

    onChange() {
      this.petData.dateOfBirth = moment(String(this.time1)).format(
        "DD/MM/YYYY"
      );
      console.log(this.petData.dateOfBirth);
    },

    validate() {
      const sp = localStorage.getItem("species");
      const errors = [];
      if (this.petData.callName.trim() === "")
        errors.callName = "Name is required";

      if (sp == 4) {
        if (this.petData.genus === "") {
          errors.genus = "Genus is required";
        }
      }
      if (sp == 5) {
        if (this.petData.customSpecies === "") {
          errors.customSpecies = "Species is required";
        }
      }
      console.log(this.questionDOB);
      if (this.questionDOB == true) {
        if (this.petData.dateOfBirth === null) {
          errors.dateOfBirth = this.$t("pet_record_error_1");
          if (sp != 4 && sp != 3 && sp != 5) {
            errors.push(this.$t("pet_record_error_1"));
            errors.push(this.$t("pet_record_error_2"));
          }
        }
      } else {
        if (this.petData.approximateAgeInMonths == "")
          errors.approximateAgeInMonths =
            "Approximate age in months is required";
        else if (!Number(this.petData.approximateAgeInMonths)) {
          errors.approximateAgeInMonths =
            "Approximate age in months must be a number";
          errors.push("Approximate age in months must be a number");
        }
      }

      if (!this.questionIdBand) {
        if (this.petData.microchipNo.trim() === "")
          errors.microchipNo = "Microchip number is required";
        else {
          var re = /^\d{1,50}$/;
          if (!re.test(this.petData.microchipNo)) {
            errors.microchipNo = "A Microchip may only consist of numbers.";

            errors.push("A Microchip may only consist of numbers.");
          } else {
            if (this.petData.microchipNo > 999999999999999999999999) {
              errors.microchipNo =
                "A Microchip must contain less than 25 digits.";
              errors.push("A Microchip must contain less than 25 digits.");
            }
          }
        }
      }
      if (!this.questionCountryOrigin) {
        if (this.petData.CountryofOrigin == "")
          errors.CountryofOrigin = "Country of Origin is required";
      }
      if (!this.questionPureBred && sp != 4 && sp != 3 && sp != 5) {
        if (this.petData.breed == "") errors.breed = "Breed is required";
      }
      if (!this.questionPedPapers && sp != 4 && sp != 3 && sp != 5) {
        if (this.petData.officialRegisteredName.trim() === "")
          errors.officialRegisteredName =
            "Official registered name is required";
        if (this.petData.pedigreeNo.trim() === "")
          errors.pedigreeNo = "Pedigree Registration Number is required";
        if (this.petData.nationalRegistry === "")
          errors.nationalRegistry = "National Registry is required";
      }
      /*  if (this.petData.petIdentificationImage == null)
        errors.push("A pet’s identity must be added"); */

      return Object.keys(errors).length === 0 ? null : errors;
    },

    async editAccount() {
      if (this.editable) {
        this.editable = !this.editable;
        this.$emit("editable", this.editable);
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.titlemenu3 = this.$t("account_dropdown_menu_edit_pet_records");
        if (this.petData.dateOfBirth === null) {
          this.errorData = true;
        }
        const errors = this.validate();
        this.errors = errors || [];
        if (errors) {
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }

        if (this.file) {
          const index = this.petData.petGallery.findIndex(
            (p) => p.primary_picture === 1
          );

          if (index !== -1) {
            this.petData.petGallery.splice(index, 1, {
              petImage: this.file,
              primary_picture: 1,
            });
            console.log(this.petData.petGallery);
          } else {
            this.petData.petGallery.push({
              petImage: this.file,
              primary_picture: 1,
            });
            console.log(this.petData.petGallery);
          }

          console.log(this.petGallery);
        }

        this.petData.dateOfBirth = moment(String(this.time1)).format(
          "DD/MM/YYYY"
        );

        console.log("9999", this.petData.petGallery);

        //savePetGallery(userEmailAddress, petImage, petID, primary_picture);

        const data = {
          petData: this.petData,
          userEmailAddress: localStorage.getItem("email"),
          vetEmailAddress: this.vetEmailAddress,
          unverifiedHealthStatus: this.unverifiedHealthStatus,
          petProfileImg: this.file ? this.file : null,
          petGallery: this.petData.petGallery,
        };
        console.log("nnnnn", data);

        this.updatePetRecord(data);
      } else {
        this.editable = !this.editable;
        this.$emit("editable", this.editable);
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.titlemenu3 = "";

        this.time1 =
          this.petData.dateOfBirth ||
          this.petData.approximateAgeInMonths + " months";
        console.log("tttttttttt : ", this.time1);
      }
    },
    openModal() {
      this.$store.commit("showDeleteModals", true);
    },
    closeMediaModals(i) {
      this.addMediaModals = i;
    },
  },
};
</script>

<style>
.filler {
  width: 180px;
  height: 0px;
}

.mx-datepicker {
  width: 100%;
}

.mx-datepicker > div > input {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.mx-input {
  border: none;
  height: auto;
}
</style>
